$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.MediaSlideshow {
  position: relative;
  // overflow: hidden
  display: flex;
  flex-direction: column;

  &-slides {
    position: relative; }

  &-stage, &-slider {
    height: 100%;
    // overflow: hidden
    position: relative; }

  &-stage {
    background: black;
    overflow: hidden; }

  &-slider {
    width: calc(var(--number-of-slides, 1) * 100%);
    left: 0;
    transition: left 200ms ease-in-out;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    > * {
      min-height: 200px;
      max-height: 700px;
      height: 100%;
      width: 100%;
      // overflow: hidden
      display: flex;
      align-items: center;
      justify-content: center; } }

  &-Slide {
    position: relative;
    > * {
      max-height: 700px;
      @include onScreensNarrowerThan('phablet') {
        max-height: 550px; }
      @include onScreensNarrowerThan('phone') {
        max-height: 400px; } } }

  &-buttons {
    @include fillPositionedParent;
    pointer-events: none;
    > * {
      pointer-events: all; } }

  &-uploading {
    position: absolute;
    margin: auto;
    @include spaced;
    height: 180px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

  &-remove {
    position: absolute;
    top: 0;
    right: 0;
    // display: block
 }    // margin-top: var(--vertical-space)

  &-prev, &-next {
    position: absolute;
    $height: 100px;
    top: calc(50% - (#{$height} / 2));
    height: $height;
    width: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 1;
    transition: opacity 200ms linear;
    &, &:focus {
      outline: none; }
    &[disabled] {
      opacity: 0;
      pointer-events: none; }
    > .Icon:before {
      color: rgba(0, 0, 0, 0.5);
      text-shadow: 0px 0px 0 rgba(255, 255, 255, 0.5);
      font-size: 30px;
      @include onScreensNarrowerThan('phablet') {
        font-size: 25px; } } }

  &-prev {
    left: 0;
    justify-content: flex-start;
    > .Icon {
      margin-left: var(--horizontal-space);
      &:before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); } } }

  &-next {
    right: 0;
    justify-content: flex-end;
    > .Icon {
      margin-right: var(--horizontal-space);
      &:before {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg); } } }

  &-Audio {
    > *:first-child {
      font-size: 200px;
      color: black;
      text-align: center; }
    > * {
      background: var(--background-grey); } }

  &-dots {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
    padding: calc(var(--vertical-space) / 2) 0;
    > button {
      display: block;
      padding: 3px;
      margin: 0 1px;
      &, &:focus {
        outline: none; }
      > div {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--grey-text); }

      &[data-selected] > div {
        background-color: var(--tru-blue); } } }

  // .ProgressPie
  //   position: absolute
  //   bottom: 0
  //   left: 0
  //   height: 4px
 }  //   background-color: var(--tru-blue)
