$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.MobilePhoneVerificationForm {
  margin-bottom: 10px;
  > .ErrorMessage {
    margin-top: 10px; }
  &-number {
    margin: 10px 0;
    padding: 0;
    input {
      font-family: monospace;
      text-align: center;
      font-size: 50px;
      letter-spacing: 0;
      height: auto; } }
  &-explainerText {
    text-align: center; } }
