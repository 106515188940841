$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.APortalAuthForm {
  width: unquote('min(85vw, 350px)');
  &-Intro {
    text-align: center; }
  &-Footer {
    text-align: center;
    margin-top: var(--vertical-space); }

  // TODO this should be generalized into TextInput
  &-password, &-passwordConfirmation {
    &-invalid {
      border-color: red; } }

  &-passwordError {
    float: right; } }
