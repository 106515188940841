$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.OrganizationConnectionsList {
  &-OrganizationPreview {
    display: flex;
    flex-direction: column;
    margin: 0 var(--horizontal-padding);
    .OrganizationBanner {
      box-shadow: var(--content-box-box-shadow);
      margin: 1px;
      width: auto; }
    > *:last-child {
      position: relative;
      padding: var(--horizontal-space) 0;
      > .OrganizationInfo {
        min-height: 30px;
        pointer-events: none; } } } }
