$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.FeedContentBox {
  position: relative;
  max-width: 100%;
  &-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--vertical-space) var(--horizontal-space);
    min-height: 45px;
    border-bottom: 1px solid var(--border-grey-lightened); }
  &-icon {
    flex-shrink: 0;
    margin-right: var(--horizontal-space);
    > *, img {
      display: block; } }
  &-title {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    overflow-x: hidden; }
  &-actions {
    flex: 0 0;
    margin-left: 5px;
    align-self: flex-start; }
  &-bottom {
    padding: var(--vertical-space) var(--horizontal-space);
    > * {
      padding: 0 0.25em;
      font-weight: bold;
      border-radius: 3px;
      color: var(--grey-text);
      &.Link, &.Button {
        transition: opacity 50ms linear;
        transition-delay: 100ms;
        &[disabled] {
          opacity: 0.3; }
        &:not([disabled]):hover {
          background-color: var(--background-grey);
          color: var(--default-font-color); } } } }

  &-Body {
    position: relative;
    &-content {
      position: relative;
      margin: 0;
      white-space: pre-wrap;
      text-overflow: ellipsis; }
    &-truncated &-content {
      overflow: hidden;
      max-height: 700px; }
    &-noMedia &-content {
      max-height: 120px; }
    &-overflows &-content {
      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        background: linear-gradient(0deg, white 0%, white 30px, white 30px, transparent 100%); } }
    &-showingMore &-content {
      overflow-y: visible;
      max-height: none; }
    &-toggle {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 5px;
      &:focus {
        @include undoInputFocusBorder; } }
    &-showingMore &-toggle {
      position: static; } }

  > *:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; } }
