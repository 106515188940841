$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.AuthorIconBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  /*
   * NOTE: the sizes in this file are duplicated in a few place
   * TODO: dry up these sizes
   * see:
   *  OrganizationFeedPostCreateForm
   *  OrganizationFeedPost
   *  OrganizationFeedPostCommentCreateForm
   */

  > *:first-child {
    flex: 0 0 auto;
    margin: 1px 10px 1px 1px; }

  > .EndUserAvatar:first-child, > *:first-child > .EndUserAvatar {
    display: block;
    margin: 0 5px;
    height: 50px;
    width: 50px; }

  > .OrganizationIcon:first-child, > *:first-child > .OrganizationIcon {
    display: block;
    height: 60px;
    width: 60px; }

  > *:last-child {
    flex: 1 1 100%;
    max-width: calc(100% - 75px); } }
