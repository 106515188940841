$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationFeedPageLayout {
  > .OrganizationPageSubnav > .HeaderedContentBox {
    display: none;
    box-shadow: none;
    border-top: 1px solid var(--border-grey-lightened);
    margin: 0px;
    @include onScreensNarrowerThan('tablet') {
     display: block; } }
  > .TwoColumns > .TwoColumns-right > div > div {
    overflow: hidden;
    max-height: 87.5%; } }
