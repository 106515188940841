$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.InterfaceWalkthrough {
  &-Content {
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overscroll-behavior: contain;
    overflow: hidden;
    > input[type=text] {
      opacity: 0;
      pointer-events: none; } }

  &-Step {
    $border-radius: 20px;
    opacity: 0;
    z-index: 5010;
    touch-action: auto;
    position: fixed;
    padding: var(--vertical-space) var(--horizontal-space);
    transition: opacity 200ms ease-in;
    > div {
      display: flex;
      flex-direction: column;
      border-radius: $border-radius;
      box-shadow: 0px 0px 54px -3px black; }

    &-content {
      flex: 1 1 calc(100% - 20px);
      overflow: auto;
      overscroll-behavior: contain;
      max-height: unquote('min(50vh, 400px)');
      max-width: unquote('min(50vw, 400px)');
      padding: var(--vertical-padding) var(--horizontal-padding) 0 var(--horizontal-padding);
      background: white;
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
      text-align: justify;
      > .Header {
        margin-bottom: var(--vertical-space); } }

    &-buttons {
      display: flex;
      flex-direction: row;
      align-items: center; }

    &-prevButton {
      z-index: 2;
      order: 1; }
    &-middle {
      z-index: 1;
      order: 2; }
    &-nextButton {
      z-index: 2;
      order: 3; }

    &-middle {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      background: white;
      align-self: stretch;
      // this little hack here is all just to make the rounded corners
      // look nicer, it fills in white space behind the buttons
      margin: 0 -20px;
      > .Link {
        font-size: 80%; } }

    &-neverLink {
      margin-bottom: 5px;
      @include faded; }
    &-closeButton {
      z-index: 5015;
      position: absolute;
      top: 11px;
      right: -2px;
      > span {
        display: block;
        background-color: white;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin: 3px;
        > .Icon:before {
          position: absolute;
          top: 1px;
          left: -1px; } } }

    &-prevButton, &-nextButton {
      background-color: white;
      transition: background-color 200ms ease-in-out;
      padding: var(--vertical-padding) var(--horizontal-padding);
      box-sizing: content-box; // fixed border draw issue
      &:not([disabled]) {
        &:focus, &:active {
          color: white;
          background-color: var(--tru-blue); } }
      &[disabled] {
        opacity: 1;
        color: rgba(0,0,0,0.3); }
      > span {
        position: relative; }
      &:not([disabled]):active > span {
        top: 2px;
        left: 2px; } }

    &-prevButton {
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius; }
    &-nextButton {
      border-top-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius; } }


  &-openTransition {
    &-enter {
      pointer-events: none;
      transition: opacity 200ms ease-in-out;
      opacity: 0; }
    &-enter-active {
      opacity: 1; }
    &-exit {
      pointer-events: none;
      transition: opacity 200ms ease-in-out;
      opacity: 1; }
    &-exit-active {
      opacity: 0; } } }
