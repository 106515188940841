$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ProductsAndServicesList {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-gap: 10px;
  @include onScreensNarrowerThan('phablet') {
    grid-template-columns: 100%; }
  .Card-Body {
    padding: 20px; }
  &-ProductOrService {
    flex: 1 1;
    margin-bottom: 0;
    &-row {
      word-wrap: break-word;
      margin-top: 10px;
      &:not(:last-child) {
        padding-bottom: 5px; }
      ~ .BuyingInterest-row {
        margin-top: 0px; }
      .Header {
        display: inline-block;
        margin-right: 5px; }
      &-name {
        font-weight: bold; } }
    .Pill {
      margin: 2px; }
    &-buttons {
      position: absolute;
      top: 5px;
      right: 5px;
      .Button {
        margin-left: 0.5em; } } } }
