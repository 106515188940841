$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.IconRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  white-space: nowrap;
  &-inline {
    display: inline-flex; }
  &-icon, &-row, &-buttons {
    display: flex;
    flex-direction: row;
    align-items: center; }
  &-row {
    flex: 1 1;
    padding-left: var(--horizontal-space);
    margin-right: var(--horizontal-space);
    &, * {
      overflow: hidden;
      text-overflow: ellipsis; } }
  &-buttons {
    flex-direction: row;
    align-items: center;
    > * {
      margin-left: 0.25em; } } }
