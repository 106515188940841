$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationFeedPost {
  &.ContentBox {
    border-radius: 20px; }
  .ContentBox + & {
    margin-top: var(--vertical-space); }

  &-title {
    display: block;
    white-space: pre;
    margin-bottom: 5px; }

  &-title,
  &-initPoster, {
    overflow: hidden;
    text-overflow: ellipsis; }

  &-poster {
    overflow: hidden;
    text-overflow: ellipsis; }

  .OrganizationFeedPostMedia {
    overflow: hidden; }

  &-textContent, &-linkContent {
    padding: 10px;
    text-overflow: ellipsis; }
  &-linkContent {
    white-space: nowrap;
    overflow: hidden;
    > .Header > .Link {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden; } }
  &-textContent {
    white-space: pre-line;
    overflow: auto; }

  &-deleted {
    margin: 10px; }

  &-hiddenWarning {
    display: flex;
    flex-director: column;
    > span {
      text-align: left;
      flex: 1 1;
      font-weight: bolder; } }

  &-RepostingModal {
    .Dropdown {
      width: 100%; } }

  &-buttonRow {
    &-button {
      font-weight: bold;
      @include onScreensNarrowerThan('phone') {
        > span:last-child {
          display: none; } }
      &:focus {
        @include inputFocusBorder; } } }

  &-PublishedBottom &-buttonRow {
    justify-content: flex-start;
    padding-bottom: var(--vertical-padding);
    &-button {
      margin-right: 1em; } }

  &-ForumBottom &-buttonRow {
    &-button {
      border-radius: 4px;
      margin-left: 1em; } }

  .FeedContentBox-icon .EndUserAvatar {
    height: 50px;
    width: 50px; }

  &-published {
    &.ContentBox {
      border-radius: 3px; }
    > .OrganizationFeedPostAuthorIcon {
      display: none; }
    > .FeedContentBox {
      max-width: 100%;
      .FeedContentBox-icon {
        display: none; } } }
  &:not(&-published) {
    > .OrganizationFeedPostAuthorIcon {
      display: block; }
    > .FeedContentBox .FeedContentBox-icon {
      display: block; } }

  .WYSIWYGContent {
    margin: 0 var(--horizontal-padding); }

  &-visibleTo {
    white-space: normal; } }
