$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.HeaderedContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: nowrap;
  > .HeaderWithButtons {
    flex: 0 0;
    margin-bottom: 0;
    padding: var(--vertical-padding) var(--horizontal-padding); }
  &-icon {
    margin-right: 0.5em; }
  &-content {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto; }
  &-padded > &-content {
    padding: var(--vertical-space) var(--horizontal-space);
    @include removeVerticalMarginFromChildren; }
  &-collapsed > &-content {
    height: 0px;
    overflow: hidden;
    pointer-events: none;
    flex: 0 0 0px;
    padding-top: 0;
    padding-bottom: 0; } }
