$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.AliceDefaultPersonalDataForm {
  &-countryField {
    position: relative;
    > .Switch, .Tooltip {
      position: absolute;
      top: 10px;
      right: 10px; } }
  textarea {
    min-height: 87px; }

  .DropdownPicker-input {
    margin-right: 50px; } } // move the X on search input to the left of the .Switch
