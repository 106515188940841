$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationChatPage {
  display: flex;
  flex-direction: column;
  height: var(--OrganizationPage-maxPageHeight);

  &-ChatContentBox {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    min-height: unquote('min(400px, calc(100vh - var(--Layout-TopNav-height, 10vh) - var(--vertical-space) - var(--vertical-space)))'); }

  @include onScreensNarrowerThan('phone') {
    --width: calc(100vw - (var(--horizontal-space) * 2));
    &-left, &-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: var(--width);
      transition: left 200ms;
      overflow: hidden; }
    &-left {
      left: 0; }
    &-right {
      left: var(--width);
      overflow {} }

    &-ChatContentBox[x-open] &-left {
      left: -100vw; }
    &-ChatContentBox[x-open] &-right {
      left: 0; } }

  @include onScreensWiderThan('phone') {
    &-backButton {
      display: none; } }

  @include onScreensWiderThan('phablet') {
    &-right {
      flex: 2 2 calc(100% - var(--width)); }
    &-left {
      --width: 200px;
      flex: 0 0 var(--width);
      width: var(--width); } }

  @include onScreensWiderThan('desktop') {
    &-left {
      --width: 300px; } }


  &-left {
    border-right: 1px solid var(--border-grey);
    display: flex;
    flex-direction: column; }

  &-right {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    > .Loading {
      flex-grow: 1; }
    > .ErrorMessage {
      margin: var(--vertical-space) var(--horizontal-space); } }

  &-right > &-ChatWindow {
    @include fillPositionedParent; }

  &-ChatWindow {
    display: flex;
    flex-direction: column;
    > .ErrorMessage {
      margin: calc( var(--vertical-space) / 2 ) var(--horizontal-space); }
    > .Loading {
      flex: 1 1; }
    &-messages {
      flex: 1 1;
      position: relative;
      > * {
        @include fillPositionedParent; } } }

  &-ChatMessages {
    &-list {
      overflow-x: auto;
      overflow-y: scroll;
      flex: 1 1;
      padding: var(--vertical-padding) var(--horizontal-padding);
      height: 100%;
      width: 100%;
      > * {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 100%; } } }

  &-scrollDownButton {
    z-index: 50;
    position: absolute;
    bottom: var(--vertical-space);
    right: var(--horizontal-space);
    transition: opacity 500ms;
    transition-delay: 100ms;
    opacity: 0.5;
    &[data-hidden] {
      opacity: 0; } }

  &-DayBreak, &-ChatMessage {
    margin-top: calc(var(--vertical-space) / 2);
    margin-bottom: calc(var(--vertical-space) / 2); }

  &-DayBreak {
    text-align: center;
    color: var(--greyed-out);
    margin: var(--vertical-space) var(--horizontal-space); }

  &-ChatMessage {
    display: flex;
    align-items: flex-end;
    &-fromMe {
      flex-direction: row-reverse; }

    .WYSIWYGContent {
      @include removeVerticalMarginFromChildren; }

    &-status {
      margin: 0 0 0 -5px;
      > .Icon {
        color: transparentize(#32aabb, 0.3);
        font-size: 10px;
        margin-bottom: 14px; } }

    &-displayName {
      display: block;
      font-weight: 500;
      margin-bottom: calc(var(--vertical-padding) / 4); } }

  &-FileAttachment {
    flex: 0 1 auto;
    display: block;
    max-width: 50%;
    margin: 0 auto 0 var(--horizontal-space);
    > .Letterbox {
      background: transparent;
      height: auto;
      width: auto; } }

  &-ChatMessage-fromMe &-FileAttachment {
    margin: 0 var(--horizontal-space) 0 auto; }

  &-FileAttachment-file {
    border: 1px solid var(--border-grey);
    background-color: var(--background-grey);
    padding: var(--vertical-padding) var(--horizontal-padding);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    > .Icon {
      margin-right: calc(var(--horizontal-space) / 2); } }

  &-fileInfo {
    display: flex;
    flex-direction: column;
    overflow: hidden; }

  &-Bubble {
    border: 1px solid var(--border-grey);
    border-radius: 20px;
    padding: 10px 15px;
    margin-left: var(--horizontal-space);
    margin-right: var(--horizontal-space);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: border-color 200ms ease-in-out;
    background-color: white;
    &-time {
      display: flex;
      justify-content: flex-end;
      margin-top: calc(var(--vertical-space) / 2); } }

  &-ChatMessage-fromMe &-Bubble {
    background-color: var(--background-grey);
    border-color: var(--background-grey); }

  &-ChatMessage-unread &-Bubble {
    border-color: var(--tru-blue); }

  &-ChatMessageForm {
    z-index: 10;
    padding: var(--vertical-padding) var(--horizontal-padding);
    padding-top: 0;
    display: flex;
    > .FileButton {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      padding: calc(var(--vertical-padding) / 2) var(--horizontal-padding); }
    > .TextArea {
      flex: 1 1 50%;
      margin-right: calc(var(--horizontal-space) / 2);
      max-height: 100px;
      max-width: 72vw; } }

  &-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: var(--horizontal-padding);

    border-bottom: 1px solid var(--border-grey);
    > *:not(:last-child) {
      margin-right: var(--vertical-space); }
    > .Header {
      flex: 1 1; } }

  @include onScreensBetween('phone', 'phablet') {
    &-left > &-header {
      > .Header {
        display: none; }
      > .Icon {
        font-size: 25px;
        position: relative;
        left: 5px; } } }

  &-Chats {
    position: relative;
    flex: 1 1; }

  &-ChatsList {
    @include fillPositionedParent;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    &-transition {
      &-enter {
        opacity: 0;
        transition: opacity 300ms ease-in; }
      &-enter-active {
        opacity: 1; }
      &-exit {
        opacity: 1;
        transition: opacity 300ms ease-in; }
      &-exit-active {
        opacity: 0; } } }

  &-ChatListMember {
    > {
      .EndUserAvatar,
      .EndUserAvatarStack,
      .ChatChannelAvatar {
        @include onScreensBetween('phone', 'phablet') {
          position: relative;
          left: 25px; } } }
    &:hover, &:focus, &:active {
      background: var(--background-grey); }
    &-selected {
      &, &:hover, &:focus, &:active {
        background: var(--background-grey-darkened); } }
    .ChatChannelListMember-details {
      @include onScreensBetween('phone', 'phablet') {
        display: none; } }
    &-unreadBadge {
      opacity: 0;
      margin-right: calc(var(--horizontal-padding) / 2);
      transition: opacity 100ms;
      @include onScreensBetween('phone', 'phablet') {
        position: absolute;
        top: var(--vertical-padding);
        right: calc(var(--horizontal-padding) / 2); } }
    &-unread &-unreadBadge {
      opacity: 1; } }

  &-CurrentChat {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%; }

  &-NoCoversationSelected {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include onScreensNarrowerThan('phone') {
      display: none; }
    .Icon {
      font-size: 100px; }
    .Header {
      margin: var(--vertical-space) 0; } }

  &-ChatMessageFailed {
    color: red;
    font-weight: bolder;
    font-size: 110%;
    padding: calc(var(--vertical-padding) / 2) calc(var(--horizontal-padding) / 2); }

  &-openNewChat {
    align-self: stretch;
    padding: 0 var(--horizontal-padding); }

  &-NewDMChatChannel {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }
