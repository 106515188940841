$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.NewChatModal {
  .StyleishModal-body {
    overflow: hidden; }
  &-form {
    display: flex;
    flex-direction: column;
    min-width: unquote('min(90vw, 500px)');
    > * {
      flex: 0 0; }
    > .Header {
      display: flex;
      align-items: center;
      min-height: 33px;
      box-sizing: content-box;
      .EndUserAvatar, .EndUserAvatarStack {
        margin-right: var(--horizontal-space); } }
    > .Header, > .ButtonRow {
      padding: var(--vertical-padding) var(--horizontal-padding);
      flex: 0 0; }
    > .PeopleList {
      flex: 1 1 50vh; } }
  .IconRowList > &-recipient {
    padding-left: var(--horizontal-padding);
    padding-right: var(--horizontal-padding);
    &:hover {
      background-color: lighten(#177bdf, 40%); }
    &-selected {
      &, &:hover {
        background-color: lighten(#177bdf, 20%); } } } }

