$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.NetworkNavigator {
  > .BreadCrumbs {
    background-color: var(--background-grey);
    border-bottom: 1px solid var(--border-grey); }

  &-crumb {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    .OrganizationIcon {
      margin-right: calc(var(--horizontal-space) / 2); } }

  &-Panels {
    display: flex;
    flex-direction: row;
    overflow: scroll; }

  &-Panel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include onScreensNarrowerThan('phablet') {
      flex: 0 0 100%;
      width: 100%; }
    @include onScreensWiderThan('phablet') {
      flex: 0 0 50%;
      width: 50%; }
    &:not(:last-child) {
      border-right: 1px solid var(--border-grey); }
    > .OrganizationIcon {
      display: block;
      margin: var(--horizontal-space) auto; }
    > .OrganizationInfo {
      @include spaced; }
    > .IconRow {
      @include spaced; }
    &-header {
      @include padded;
      display: flex;
      align-items: center;
      .OrganizationIcon {
        margin-right: var(--horizontal-space); } } }

  &-HelpPanel {
    @include onScreensNarrowerThan('phablet') {
      display: none; }

    .Icon {
      font-size: 150px;
      margin: var(--horizontal-space) auto; } }

  &-org {
    &-not-selected {
      opacity: 0.25; } }

  &-NetworkMembers {
    &-header {
      padding-left: var(--horizontal-padding); } } }
