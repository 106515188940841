$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Alert {
  --Alert-border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  border-radius: var(--Alert-border-radius);
  background-color: #fff;
  transition: opacity 200ms;
  opacity: 1;
  width: fit-content;
  border-left: 5px solid var(--Alert-dark-color);

  &-withTitle {
    border-left: 0px; }
  &-title {
    background-color: var(--Alert-dark-color);
    color: white;
    font-weight: bold;
    text-align: center;
    border-top-left-radius: var(--Alert-border-radius);
    border-top-right-radius: var(--Alert-border-radius);
    padding: 4px 1em; }

  &-body {
    display: flex;
    background-color: var(--Alert-light-color);
    border-bottom-left-radius: var(--Alert-border-radius);
    border-bottom-right-radius: var(--Alert-border-radius); }

  &-icon {
    padding: var(--vertical-padding) var(--horizontal-padding);
    color: var(--Alert-dark-color);
    align-self: center; }
  &-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    word-break: break-word;
    padding: var(--vertical-padding) var(--horizontal-padding);
    padding-left: 0; }

  &-placeholder {
    opacity: 0; }

  &-success {
    --Alert-light-color: #f0f9eb;
    --Alert-dark-color:  #67c23a; }
  &-info {
    --Alert-light-color: #f4f4f5;
    --Alert-dark-color:  #909399; }
  &-warning {
    --Alert-light-color: #fdf6ec;
    --Alert-dark-color:  #e6a23c; }
  &-error {
    --Alert-light-color: #fef0f0;
    --Alert-dark-color:  var(--error-red); }

  &-dismissButton {
    display: flex;
    align-items: center;
    padding: 0 var(--horizontal-padding);
    border-left: 1px solid var(--border-grey-lightened);
    cursor: pointer;
    color: var(--greyed-out);
    font-size: 1em; } }
