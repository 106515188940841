$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.VoteArrow {
  &:focus {
    @include inputFocusBorder; }
  &[disabled] {
    opacity: 1;
    cursor: initial; }

  &-active.Button {
    &, &:not([disabled]):hover {
      color: var(--tru-blue); } }
  > .Icon:before {
    font-size: 120%; }
  > span:last-child {
    display: inline-block;
    min-width: 1em; }

  &-arrowBounceUp {
    animation-fill-mode: both;
    animation: OrganizationFeedPost-arrowBounceUp 1s; }

  &-arrowBounceDown {
    animation-fill-mode: both;
    animation: OrganizationFeedPost-arrowBounceDown 1s; } }


@keyframes OrganizationFeedPost-arrowBounceUp {
  0%, 30%, 60%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(-7px); }
  40% {
    transform: translateY(-4px); } }

@keyframes OrganizationFeedPost-arrowBounceDown {
  0%, 30%, 60%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(7px); }
  40% {
    transform: translateY(4px); } }
