$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.WYSIWYGEditor {
  --ck-color-link-default: var(--dark-navy-blue);
  --ck-border-radius: 4px;
  --ck-color-toolbar-border: var(--border-grey);
  --ck-color-base-border: var(--border-grey);
  --ck-focus-ring: none;

  .ck.ck-toolbar {
    border: none; }

  .ck.ck-content {
    &, &.ck-focused, &.ck-editor__editable_inline {
      border: none; } }

  &-unsaved {
    &, &[disabled] {
      .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border-color: var(--unsaved-border-color);
        background-color: var(--unsaved-background-color); } } }

  .ck-content {
    min-height: 150px; }

  .ck-editor__main {
    padding-top: 38px; }

  .ck.ck-editor {
    position: inherit; }

  .ck-editor__top.ck-reset_all {
    position: absolute !important;
    width: 100%;
    border-bottom: 1px solid var(--border-grey);
    z-index: 2; }

  .ck-sticky-panel__placeholder {
    display: none !important; }

  .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: relative !important;
    width: 100% !important; } }
