$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationChannelPage {
  .FeedPostForm,
  &-FeedControls {
    margin-bottom: var(--vertical-space); }

  .FeedPostForm .WYSIWYGInput {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }

  &-feedControl {
    .Icon {
      margin-right: 0.5em; }
    .Icon-top {
      margin-right: 0.75em; } }

  @include onScreensNarrowerThan('phone') {
    &-FeedControls &-feedControl {
      justify-content: flex-start;
      .Icon {
        margin-left: 40%; } }
    &-feedControl:first-of-type {
      margin-top: 0; } } }
