$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  // flex-wrap: nowrap

  @include onScreensNarrowerThan('phone') {
    padding: var(--horizontal-space) var(--horizontal-space);
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap; }

  @include onScreensWiderThan('phone') {
    padding: 0 var(--horizontal-space) 0 var(--horizontal-space);
    min-height: 68px;
    &-thin {
      min-height: 50px; } }

  > .Header {
    padding-top: var(--vertical-space);
    padding-bottom: var(--vertical-space);
    > .Icon {
      margin-right: var(--horizontal-space); }
    @include onScreensNarrowerThan('phone') {
      width: 100%;
      flex: 0 0 100%; }
    @include onScreensWiderThan('phone'); }


  & > &-Space {
    @for $i from 1 through 8 {
      &-#{$i} {
        flex: 0 0 calc(var(--horizontal-space) * #{$i});
        width: calc(var(--horizontal-space) * #{$i}); } } }

  & > &-Grow {
    flex: 2 2 auto; }

  & > &-Break {
    flex-basis: 100%;
    width: 0; }

  > .Button {
    &-normal, &-primary, &-success, &-transparent {
      @include onScreensNarrowerThan('phone') {
        margin: 0;
        &:not(:first-of-type) {
          margin-top: var(--vertical-space); } }
      @include onScreensWiderThan('phone') {
        margin: var(--vertical-space) 0;
        margin-left: var(--horizontal-space); } } }

  > .IconButton {
    margin: var(--vertical-space) 0;
    margin-left: var(--horizontal-space); }

  > .PageTab {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-around;
    --PageTab-horizontal-padding: calc(var(--horizontal-space) / 2);
    padding: calc(var(--vertical-space) / 2) var(--PageTab-horizontal-padding);

    @include onScreensNarrowerThan('phone') {
      margin: var(--vertical-space) 0 0 0;
      background-color: var(--background-grey);
      &-selected {
        background-color: var(--tru-blue);
        color: white; } }
    @include onScreensWiderThan('phone') {
      margin: calc(var(--vertical-space) / 2) 0;
      &-selected {
        &:after {
          position: absolute;
          background-color: var(--tru-blue);
          bottom: calc(var(--vertical-space) * -0.5);
          left: var(--PageTab-horizontal-padding);
          right: var(--PageTab-horizontal-padding);
          height: 3px;
          content: " "; } } } } }
