$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationFeedPostComments {
  &-text {
    padding: 10px; }
  &-Comment {
    @include spaceVertically;

    .FeedContentBox-top {
      align-items: center; }

    @include onScreensNarrowerThan('phone') {
      > .OrganizationFeedPostAuthorIcon {
        display: none; }
      > *:first-child {
        display: none; }
      > *:last-child {
        max-width: 100%; }

      .OrganizationFeedPostAuthorIcon {
        vertical-align: middle;
        .EndUserAvatar {
          margin: 5px;
          height: 50px;
          width: 50px; }
        .OrganizationIcon {
          height: 60px;
          width: 60px; } } }

    @include onScreensWiderThan('phone') {
      .FeedContentBox-icon {
        display: none; } } }

  .WYSIWYGContent {
    margin: 0 var(--horizontal-padding); }
  .FeedContentBox-Body-content > .PlainText {
    padding: var(--vertical-padding) var(--horizontal-padding); } }
