$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.StepDots {
  display: flex;
  flex-direction: row;
  &-dot {
    opacity: 0.25;
    &-selected {
      opacity: 1; }
    > div {
      height: 10px;
      width: 10px;
      background-color: black;
      border-radius: 50%;
      margin: 5px; } } }
