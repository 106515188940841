$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.OrganizationFeedPostProvenance {
  &-event {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: 10px; }
    > div:last-child {
      margin-left: 1em;
      flex-grow: 1;
      > small {
        display: flex;
        flex-direction: row;
        > span {
          &:first-child {
            flex: 1 1; }
          &:not(:last-child) {
            padding-right: calc(var(--horizontal-space) / 4); } } } } } }

