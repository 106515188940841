$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.HubsPage {
  @include onScreensWiderThan('phone') {
    &-Nav > .Navbar {
      > .Header {
        margin-right: var(--horizontal-space); }
      > .SearchInput {
        margin-right: auto;
        &-empty {
          > .TextInput {
            transition: width 200ms ease-out, border-color 200ms;
            width: 30px;
            border-color: transparent; } }
        &:focus-within, &:not(.SearchInput-empty) {
          > .TextInput {
            width: unquote('calc(min(var(--page-column-width), 100vw) - 500px)'); } } } } }
  @include onScreensNarrowerThan('phablet') {
    .OrganizationMembershipPills {
      display: none; }
    &-Nav > .Navbar {
      > .PageTab {
        padding: calc(var(--vertical-space) / 2) calc(var(--horizontal-space) / 2); }
      > .SearchInput {
        &-empty {
          > .TextInput {
            transition: none; } }
        &:focus-within, &:not(.SearchInput-empty) {
          > .TextInput {
            width: unquote('calc(min(var(--page-column-width), 100vw) - 154px)'); } } } } }
  @include onScreensNarrowerThan('phone') {
    &-createButton > span {
      display: none; } } }
