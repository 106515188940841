$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.MyDataDefaultsForm {
  position: relative;
  > .Spinner {
    position: absolute;
    top: 0;
    left: 0; }
  &-main {
    .HeaderWithButtons-underlined {
      padding-left: 0; } }
  &-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-height: 40px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0px 2px 2px -2px #9f9f9f;
    @include onScreensNarrowerThan('tablet') {
      left: 0; }
    > .Button, > .ButtonWithDropdown {
      margin-left: 10px; }
    > .ErrorMessage {
      position: absolute;
      top: 100%; } }
  &-error {
    top: 100%;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    @include onScreensWiderThan('tablet') {
      margin-right: 45px;
      margin-left: 45px; } }
  &-applyAllPrompt {
    .Header-md {
      margin-bottom: 10px; }
    .Header-sm {
      display: inline; } }
  &-changeSummary {
    > div:not(:last-child) {
      padding-bottom: 5px; } }
  .HeaderedContentWithUnsaved .HeaderedContent-content {
    padding-top: var(--vertical-space); } }
