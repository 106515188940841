$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BannerNav {
  &-Banner {
    display: block;
    margin-top: calc(var(--Layout-top-padding) * -1); }

  &-DesktopNav {
    top: 0px;
    @include contentBoxBorder;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    flex-wrap: nowrap;
    background: white;
    margin: 0 1px calc(var(--vertical-space) + 1px) 1px;
    &-icon {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      .OrganizationIcon, .EndUserAvatar {
        display: block;
        height: 100%;
        width: 100%; } } }

  &-DesktopNav &-NavButton .Icon {
    display: none; }

  &-MobileNav {
    // --height: 50px
    --bottom-overflow: 100px;
    z-index: 100;
    position: fixed;
    left: -3px;
    right: -3px;
    height: calc(var(--height) + var(--bottom-overflow));
    bottom: calc(var(--bottom-overflow) * -1);
    padding: 0 var(--horizontal-space) var(--bottom-overflow) var(--horizontal-space);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    .PageTab {
      font-size: 80%;
      min-width: 70px;
      &-selected {
        color: var(--tru-blue);
        &:after {
          content: none; } }
      > div {
        display: flex;
        flex-direction: column;
        height: var(--height);
        justify-content: center;
        padding-top: 7px;
        .Icon {
          margin-bottom: 5px; } } } }

  @include onScreensNarrowerThan('tablet') {
    &-DesktopNav .PageTab {
      display: none; }

    &-DesktopNav-icon {
      height: 80px;
      width: 80px;
      margin-top: -30px; }

    &-MobileNav {
      --height: var(--BannerNav-MobileHeight); } }

  @include onScreensWiderThan('tablet') {
    &-MobileNav {
      --height: 0px;
      display: none; }

    &-DesktopNav {
      z-index: 6;
      position: -webkit-sticky;
      position: sticky;
      transition: top 200ms;
      top: var(--Layout-TopNav-visibleHeight);
      height: var(--Layout-TopNav-height); }

    &-DesktopNav-icon {
      --min-size: 40px;
      --max-size: 110px;
      --scale: 1; // 0...1
      --size: calc(((var(--max-size) - var(--min-size)) * var(--scale)) + var(--min-size));
      height: var(--size);
      width: var(--size);
      margin-top: calc((var(--size) - 40px) * -1); } }

  @include onScreensNarrowerThan('phone') {
    &-DesktopNav {
      flex-direction: row;
      flex-wrap: wrap;
      &-name {
        order: 2;
        flex-basis: 100%; } } } }
