$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.HomePage {
  &-nav {
    margin-bottom: var(--vertical-space);
    > .Navbar > .Link:last-child {
      margin-left: auto;
      @include onScreensNarrowerThan('phone') {
        display: none; } } }
  &-noOrgs {
    text-align: center;
    font-weight: bolder; } }
