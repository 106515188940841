$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Layout {
  // DO NOT use overflow here, it breaks position sticky
  min-height: 100vh;
  min-width: 320px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: var(--background-grey);
  --body-background-color: white;
  --Layout-TopNav-top: 0px;
  --Layout-TopNav-height: 68px;
  --Layout-TopNav-visibleHeight: calc(var(--Layout-TopNav-top) + var(--Layout-TopNav-height));
  --Layout-top-padding: calc(var(--vertical-space) * 0.75);
  --Layout-top-offset: calc(var(--Layout-TopNav-visibleHeight) + var(--Layout-top-padding));
  --TwoColumns-top-offset: var(--Layout-top-offset);

  @include onScreensNarrowerThan('phone') {
    --Layout-TopNav-height: 106px;
    --Layout-TopNav-top: -50px;
    --TwoColumns-right-max-top: 18px; }

  @include onScreensWiderThan('phone') {
    --Layout-TopNav-height: 68px; }

  &-TopNav-hidden {
    --Layout-TopNav-top: calc(var(--Layout-TopNav-height) * -1); }

  @include onScreensNarrowerThan('phone') {
    // TMP undo respinsive changes in <Navbar/>
    &-TopNav > .Navbar {
      padding: 0 var(--horizontal-space) 0 var(--horizontal-space);
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      > .PageTab {
        background-color: white; } } }

  &-main, &-TopNav > .Navbar {
    // width: unquote('min(var(--page-column-width), calc(100vw - calc(var(--horizontal-space) * 2)))')
    @include onScreensNarrowerThan('desktop') {
      width: unquote('calc(100vw - calc(var(--horizontal-space) * 2))'); }
    @include onScreensWiderThan('desktop') {
      width: var(--page-column-width); }
    margin: 0 auto; }

  &-logo {
    flex: 0 0 auto;
    align-self: stretch;
    display: flex;
    align-items: center;
    > .TruLogo {
      display: block; } }

  &-main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: var(--Layout-top-padding);
    padding-bottom: var(--vertical-padding); }

  &-TopNav {
    position: -webkit-sticky;
    position: sticky;
    transition: top 200ms;
    height: var(--Layout-TopNav-height);
    top: var(--Layout-TopNav-top);

    z-index: 10;
    $topnav-vertical-padding: 10px;
    flex: 0 0 auto;
    background-color: white;
    box-shadow: var(--content-box-box-shadow);

    > .Navbar {
      > .PageTab-selected {
        color: var(--tru-blue);
        &:after {
          content: none; } } }

    & > &-desktop {
      flex-wrap: nowrap;

      > .Layout-MoreMenu {
        margin-left: 2%;
        margin-right: 2%; }
      > .NotificationsButton {
        margin-left: 2%;
        padding: var(--vertical-padding) var(--horizontal-padding); }
      > .NotificationsButton,
      > .ChatNotificationsButton {
        align-self: stretch;
        margin: calc(var(--vertical-space) / 2) 0;
        padding: var(--vertical-padding) calc(var(--horizontal-padding) / 2); } }

    & > &-mobile {
      margin: 0;
      width: 100vw;
      min-height: 50px;
      flex-wrap: nowrap;
      > .Layout-TopNav-Button,
      > .NotificationsButton {
        padding: 10px;
        margin: 0; }
      &:last-child {
        justify-content: space-between; } }

    @include onScreensNarrowerThan('phone') {
      & > &-desktop {
        display: none; }
      & > &-mobile {
        display: flex; }
      & &-LoginDropdown {
        display: none; } }
    @include onScreensWiderThan('phone') {
      & > &-desktop {
        display: flex; }
      & > &-mobile {
        display: none; }
      & &-Login {
        display: none; } }

    &-Button {
      @include onScreensNarrowerThan('phablet') {
        > span {
          display: none; } } } }

  &-TopNav &-backButton,
  &-TopNav &-forwardButton,
  &-TopNav &-reloadButton {
    margin: 0;
    padding: calc(var(--vertical-space) / 2) var(--horizontal-space); }
  &-TopNav &-backButton {
    margin-left: calc(var(--horizontal-space) * -1); }

  &-SearchBar {
    width: auto;
    margin-left: 10px;
    margin-right: auto;
    transition: flex 200ms;
    > .TextInput {
      transition: border-color 200ms; }

    // open state
    & {
      flex: 1 1 auto; }
    // closed state
    &.SearchInput-empty:not(:focus-within) {
      flex: 0 0 30px;
      > .TextInput {
        border-color: transparent; } } }

  &-SearchBar {
    @include isDataYogi {
      opacity: 0;
      pointer-events: none; } }

  &-CurrentUser-Menu {
    align-self: stretch;
    margin: 0;
    > .Link {
      display: block;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 calc(var(--horizontal-padding) / 2); } } }
