$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationSisaEventPage {
  &-row {
    padding: 12px 0;

    @include onScreensWiderThan('tablet') {
      display: flex;
      flex-direction: row;
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-grey-lightened); }
      > *:first-child {
        flex: 0 0 170px; } }

    @include onScreensNarrowerThan('tablet') {
      display: block; }

    > *:first-child {
      color: rgb(135, 141, 153);
      font-weight: bolder; }
    > *:last-child {
      word-wrap: break-word; }

    &-headerWithHelpButton {
      display: flex;
      .HelpButton {
        margin-left: 3px; } } }

  &-change {
    padding: 2px 0;
    &-changedTo {
      background-color: #f5f7fa;
      padding: 10px;
      margin-top: 5px;
      white-space: pre-line; } }

  &-changesWrapper {
    width: 100%; }

  &-Field {
    color: var(--dark-navy-blue);
    font-weight: bold; } }
