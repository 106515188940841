$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.EndUserAvatar {
  user-select: none;
  display: inline-block;
  border-radius: 100%;
  max-width: initial;
  overflow: hidden;
  flex-shrink: 0;
  &.ImageWithLoader-errored,
  &.ImageWithLoader-empty {
    background-image: url("components/AvatarInput/default.png");
    background-size: contain; }

  &-sm {
    height: 33px;
    width: 33px; }
  &-md {
    height: $AVATAR_HEIGHT / 2;
    width: $AVATAR_WIDTH / 2; }
  &-lg {
    height: $AVATAR_HEIGHT;
    width: $AVATAR_WIDTH; }

  &-bordered {
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.20); } }
