$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationFeedPostCommentCreateForm {
  @include onScreensNarrowerThan('phone') {
    > .OrganizationFeedPostAuthorIcon {
      display: none; }
    > *:first-child {
      display: none; }
    > *:last-child {
      max-width: 100%; }

    .OrganizationFeedPostAuthorIcon {
      vertical-align: middle;
      .EndUserAvatar {
        margin: 5px;
        height: 50px;
        width: 50px; }
      .OrganizationIcon {
        height: 60px;
        width: 60px; } } }

  @include onScreensWiderThan('phone') {
    .PostAsOrganizationDropdown .OrganizationFeedPostAuthorIcon {
      display: none; } }

  .WYSIWYGInput {
    max-height: 60vh;
    overflow: auto;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid var(--border-grey);
    border-top: 1px solid var(--border-grey); }

  .RichMediaInput {
    margin-bottom: -60px;
    textarea {
      padding-right: 0;
      padding-bottom: 0;
      &:focus {
        box-shadow: none; } }
    .FileButton {
      z-index: 2;
      padding: var(--vertical-padding) var(--horizontal-padding); }
    &-withFiles {
      margin-bottom: 0;
      .MediaSlideshow {
        padding-top: var(--vertical-space); }
      .FileButton {
        padding-bottom: 0; } } }

  &-topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--horizontal-padding) var(--horizontal-padding) 0;
    .PostAsOrganizationDropdown > .Dropdown {
      padding-left: 0; } }

  .FeedPostAndCommentForm {
    position: relative;
    &-Controls {
      padding: 0 var(--horizontal-padding) var(--vertical-padding); } } }
